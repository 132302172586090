import Vue from 'vue'
import VueRouter from 'vue-router'
import PageView from '../views/PageView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: PageView,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/IndexView.vue')
      },
      {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/join',
        name: 'join',
        component: () => import(/* webpackChunkName: "join" */ '../views/Join.vue')
      },
      {
        path: '/case',
        name: 'case',
        component: () => import(/* webpackChunkName: "join" */ '../views/Case.vue')
      },
      {
        path: '/news',
        name: 'news',
        component: () => import(/* webpackChunkName: "join" */ '../views/News.vue')
      }
    ]
  },
  {
    path: '*',
    name: '404',
    // component: IndexView
  }
]

let routerNameArr = []
var getRouterName = (routeItem) => {
  if(Array.isArray(routeItem)){
    routeItem.forEach(element => {
      getRouterName(element)
    });
  }else{
    let {path, children} = routeItem
    if(path != "*" && !routerNameArr.includes(path)){
      if(children && children.length){
        getRouterName(children)
      }else{
        routerNameArr.push(path)
      }
    }
  }
}
getRouterName(routes)

Vue.prototype.$routerNameArr = routerNameArr

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
