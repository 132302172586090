import Vue from 'vue'
import axios from 'axios'
import {
  message,
} from 'ant-design-vue'

// let baseUrl = ''
let baseUrl = 'https://webapi.ipozb.com/'

let AXIOS_REQUEST = axios.create({
  baseURL: baseUrl || process.env.API_ROOT, 
  responseType: 'json',
  timeout: 1000 * 60 * 20, //超时时间设置为20分钟
  validateStatus(status) {
    // 200 外的状态码都认定为失败
    return status === 200
  }
})

// 拦截请求
AXIOS_REQUEST.interceptors.request.use(config => {
    // config.headers['X-Access-Token'] = 'token';
    return config
}, error => {
    return Promise.reject(error)
})

// 拦截响应
AXIOS_REQUEST.interceptors.response.use(
  config => {
    if (!config || !config.data) {
      return config
    }
    let status = config.data.code
    if (!status) {
      return config
    }
    if (status === 200 || status === 1) {
      return config.data
    }
    return config
}, error => {
    return Promise.reject(error)
})

const request = {
  post(url, params) {
      return AXIOS_REQUEST.post(url, params, {
      transformRequest: [
        params => {
          let result = ''
          result = JSON.stringify(params)
          return result
        }
      ],
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  get(url, params) {
    let _params
    if (Object.is(params, undefined)) {
      _params = ''
    } else {
      _params = '?'
      for (let key in params) {
        if (params.hasOwnProperty(key) && params[key] !== null) {
          _params += `${key}=${params[key]}&`
        }
      }
    }
    return AXIOS_REQUEST.get(`${url}${_params}`)
  },
  download(url, params, filename) {
    message.loading('文件下载中')
    return AXIOS_REQUEST.post(url, params, {
      transformRequest: [
        params => {
          let result = ''
          Object.keys(params).forEach(key => {
            if (
              !Object.is(params[key], undefined) &&
              !Object.is(params[key], null)
            ) {
              result +=
                encodeURIComponent(key) +
                '=' +
                encodeURIComponent(params[key]) +
                '&'
            }
          })
          return result
        }
      ],
      responseType: 'blob'
    })
      .then(r => {
        const content = r.data
        const blob = new Blob([content])
        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a')
          elink.download = filename
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, filename)
        }
      })
      .catch(r => {
        console.error(r)
        message.error('下载失败')
      })
  },
  upload(url, params) {
    return AXIOS_REQUEST.post(url, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

Vue.prototype.$get = request.get
Vue.prototype.$post = request.post
Vue.prototype.$download = request.download
Vue.prototype.$upload = request.upload

export default request
