const apiUrl = { //
    newsList: '/web-api/website/newsInfo/list', // 新闻列表
    newsDetail: '/web-api/website/newsInfo/queryById', // 新闻列表-详情
    caseList: '/web-api/website/serviceCase/list', // 服务列表
    bannerList: '/web-api/website/siteBackdrop/getSiteBackdrop', // 首页大图，banner
    teamList: '/web-api/website/managementTeam/list', // 团队列表
    siteInfo: '/web-api/website/siteManage/getSiteManage', // 网站信息
    jobList: '/web-api/website/jobRecruitment/list', // 招聘列表
}
export default { 
    ...apiUrl
 }