<template>
  <div class="page-view" ref="page-ref">
    <router-view/>
    <!-- 7/7 底部footer -->
    <div class="ipo-footer-view" ref='lianxi-ref'>
      <footer-mode :dataProps="siteObj"></footer-mode>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import FooterMode from '@/components/Footer.vue'
import apiUrl from "@/utils/apiUrl";

export default {
  name: 'PageView',
  components: {
    FooterMode
  },
  data(){
    return {
      siteObj: {}
    }
  },
  watch: {
    dataProps() {
      this.dataObj = this.dataProps
    },
    '$route': function(newVal, oldVal){
      let hashTab = this.$route.hash
      if(!hashTab){
        let p_ref = this.$refs['page-ref']
        if(p_ref){
          this.$nextTick(()=>{
            p_ref.scrollIntoView()
          })
        }
      }else{
        hashTab = hashTab.substr(1)
        let c_ref = this.$refs[hashTab+'-ref']
        if(c_ref){
          this.$nextTick(()=>{
            c_ref.scrollIntoView()
          })
        }
      }
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData() {
      this.getSiteInfo()
    },
    getSiteInfo() {
      this.$get(apiUrl.siteInfo).then((res) => {
        let {code, result} = res || {}
        if(res && code == 200 && result){
          this.siteObj = result
        }
      }).catch((r) => {
        console.error(r)
        // this.$message.error('获取首页信息失败')
      })
    }
  }
}
</script>
