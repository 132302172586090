<template>
  <div class="footer-info">
    <a-row class="ipo-pc-row">
      <a-col :span="6" :xs="24" :ms="24" :md="6" class="footer-qcord">
        <p class="wei-view"><img :src="dataObj.siteRecord" class="weixin-qcord"></p>
        <p class="wei-font">微信公众号</p>
      </a-col>
      <a-col :span="6" :xs="24" :ms="24" :md="6" class="footer-col">
        <a-row type="flex" justify="start" align="top">
          <a-col>
            <a-row>
              <a-col>
                <p class="tips-font">PHONE</p>
              </a-col>
              <a-col><p class="footer-p3">联系电话：</p></a-col>
            </a-row>
          </a-col>
          <a-col>
            <span class="info-icon"><img src="@/assets/shou dianhua2x.png" class="img1"></span>
          </a-col>
        </a-row>
        <p class="footer-p3 footer-p3-font">010-89508968</p>
        <div class="footer-line"><span class="line-el"></span></div>
      </a-col>
      <a-col :span="6" :xs="24" :ms="24" :md="6" class="footer-col">
        <a-row type="flex" justify="start" align="top">
          <a-col>
            <a-row>
              <a-col>
                <p class="tips-font">ADDRESS</p>
              </a-col>
              <a-col><p class="footer-p3">企业地址：</p></a-col>
            </a-row>
          </a-col>
          <a-col>
            <span class="info-icon"><img src="@/assets/shou dizhi2x.png" class="img2"></span>
          </a-col>
        </a-row>
        <p class="footer-p3 footer-p3-font">北京市西城区地安门外大街95号 </p>
        <p class="footer-line"><span class="line-el"></span></p>
      </a-col>
      <a-col :span="6" :xs="24" :ms="24" :md="6" class="footer-col">
        <a-row type="flex" justify="start" align="top">
          <a-col>
            <a-row>
              <a-col>
                <p class="tips-font">EMAIL</p>
              </a-col>
              <a-col><p class="footer-p3">联系邮箱：</p></a-col>
            </a-row>
          </a-col>
          <a-col>
            <span class="info-icon"><img src="@/assets/shou youxiang2x.png" class="img3"></span>
          </a-col>
        </a-row>
        <p class="footer-p3 footer-p3-font">contact@ipozb.com</p>
        <div class="footer-line"><span class="line-el"></span></div>
      </a-col>
    </a-row>
    <a-row class="ipo-mobile-row">
      <a-col class="footer-qcord">
        <!-- <div class="wei-wrapper"> -->
          <p class="wei-view"><img :src="dataObj.siteRecord" class="weixin-qcord"></p>
          <p class="wei-font">微信公众号</p>
        <!-- </div> -->
      </a-col>
      <a-col :span="6" :xs="24" :ms="24" :md="6" class="footer-col">
        <a-row type="flex" justify="start" align="middle">
          <a-col>
            <span class="info-icon"><img src="@/assets/shou dianhua2x.png" class="img1"></span>
          </a-col>
          <a-col :span="12">
            <a-row>
              <a-col><p class="footer-p3">联系电话：</p></a-col>
              <a-col><p class="footer-p3">010-89508968</p></a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6" :xs="24" :ms="24" :md="6" class="footer-col">
        <a-row type="flex" justify="start" align="middle">
          <a-col>
            <span class="info-icon"><img src="@/assets/shou youxiang2x.png" class="img3"></span>
          </a-col>
          <a-col :span="12">
            <a-row>
              <a-col><p class="footer-p3">联系邮箱：</p></a-col>
              <a-col><p class="footer-p3">contact@ipozb.com</p></a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="6" :xs="24" :ms="24" :md="12" class="footer-col">
        <a-row type="flex" justify="start" align="middle">
          <a-col>
            <span class="info-icon"><img src="@/assets/shou dizhi2x.png" class="img2"></span>
          </a-col>
          <a-col>
            <a-row>
              <a-col><p class="footer-p3">企业地址：</p></a-col>
              <a-col><p class="footer-p3">北京市西城区地安门外大街95号</p></a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <div class="copyright" v-html="dataObj.tailInfo"></div>
  </div>
</template>

<script>
import apiUrl from "@/utils/apiUrl";
export default {
  name: 'FooterMode',
  data(){
    return {
      dataObj: {}
    }
  },
  props: {
    dataProps: {
      type: Object,
    }
  },
  watch: {
    dataProps() {
      this.dataObj = this.dataProps
    },
    // '$route':function(newVal, oldVal){
    //   console.log('newVal', newVal, oldVal)
    //   let hashTab = this.$route.hash
    //   hashTab = hashTab.substr(1)
    //   let c_ref = this.$refs[hashTab+'-ref']
    //   if(c_ref){
    //     this.$nextTick(()=>{
    //       c_ref.scrollIntoView()
    //     })
    //   }
    // },
  },
  created () {
    this.dataObj = this.dataProps
    // this.initData()
  },
  methods: {
    initData() {
      let dataProps = this.dataProps
      if(!dataProps || dataProps.tailInfo){
        this.getSiteInfo()
      }else{
        //
      }
    },
    getSiteInfo() {
      this.$get(apiUrl.siteInfo).then((res) => {
        let {code, result} = res || {}
        if(res && code == 200 && result){
          this.dataObj = result
        }
      }).catch((r) => {
        console.error(r)
        // this.$message.error('获取首页信息失败')
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer-info{
  background-color: $color-151b;
  font-family: $font-SiYuan-Medium;
  font-size: $base-footer-size2;
  color: $color-white;
  text-align: left;
  padding: $footer-info-padding-top $footer-info-padding-left 0;
  .wei-view{
    text-align: center;
  }
  .weixin-qcord{
    width: $weixin-qcord-width;
    height: $weixin-qcord-width;
    background-color: #fff;
  }
  .wei-font{
    font-family: $font-SiYuan-Light;
    text-align: center;
  }
  .footer-p3, .wei-font{
    line-height: 2.5;
  }
  .footer-p3-font{
    line-height: 1;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .tips-font{
    color: $color-3948;
    margin-bottom: -5%;
  }
  .info-icon{
    width: $footer-icon-width;
    height: $footer-icon-width;
    display: inline-flex;
    border-radius: 50%;
    border: 1px solid $color-293;
    overflow: hidden;
    box-sizing: content-box;
    background-color: $color-293;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
    .img1{
      width: $icon-img1-width;
      height: $icon-img1-height;
    }
    .img2{
      width: $icon-img2-width;
      height: $icon-img2-height;
    }
    .img3{
      width: $icon-img3-width;
      height: $icon-img3-height;
    }
  }
  .footer-col {
    .footer-line{
      .line-el{
        width: 2em;
        display: inline-block;
        border-bottom: 2px solid $color-white;
      }
    }
    &:hover{
      .footer-line{
        .line-el{
          width: 4em;
          border-bottom: 2px solid $color-577;
        }
      }
      .info-icon{
        border-color: $color-white;
      }
    }
  }
  .copyright{
    text-align: center;
    padding-top: 2em;
    margin-top: 2em;
    padding-bottom: 2em;
    border-top: 1px dotted $color-bf;
    // padding-bottom: $footer-info-padding-bottom;
    ::v-deep p {
      line-height: 1.5;
    }
  }
}
@media only screen and (max-width: 768px) {
  // 移动设备
  .ipo-pc-row{
    display: none!important;;
  }
  .footer-info{
    padding: 32px;
    font-size: $base-mobile-content-font;
    .copyright{
      margin-top: 8px;
      padding: 16px 0 0;
      ::v-deep p{ line-height: 1.5;}
    }
  }
  .ipo-mobile-row{
    .footer-p3{
      line-height: 1.5;
      padding-left: 8px;
    }
    .footer-col{
      padding-bottom: 16px;
      margin-top: 3vw;
      margin-bottom: 3vw;
    }
    .weixin-qcord{
      width: $weixin-qcord-mobile-width;
      height: $weixin-qcord-mobile-width;
    }
    .info-icon{
      width: $footer-icon-mobile-width;
      height: $footer-icon-mobile-width;
      .img1{
        width: $icon-img1-mobile-width;
        height: $icon-img1-mobile-height;
      }
      .img2{
        width: $icon-img2-mobile-width;
        height: $icon-img2-mobile-height;
      }
      .img3{
        width: $icon-img3-mobile-width;
        height: $icon-img3-mobile-height;
      }
    }
    .footer-qcord{
      position: absolute;
      right: 0;
    }

  }
}
@media only screen and (max-width: 767px) {
  .ipo-mobile-row{
    .footer-col{
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
@media only screen and (min-width: 769px) {
  // pc 设备
  .ipo-mobile-row{
    display: none!important;;
  }
}
</style>
