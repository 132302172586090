import Vue from 'vue'
import { Button, Row, Col, Input, Form, Radio, Select, Collapse, Tabs, Empty, Popover,
    Card,
    List,
    Pagination,
    Icon,
    Affix,
    Dropdown,
    Menu,
    BackTop,
 } from 'ant-design-vue'; 

const FormItem = Form.Item; 
// const Option = Select.Option; 
const TabPane = Tabs.TabPane 

const antdArr = [
    Button,
    Row,
    Col,
    Input,
    Form,
    FormItem,
    Radio,
    Select,
    // Option,
    Collapse,
    Tabs,
    TabPane,
    Popover,
    Card,
    List,
    Pagination,
    Icon,
    Affix,
    BackTop,
    Dropdown,
    Menu,
    Empty
]

antdArr.forEach(item => {
    Vue.use(item)
})

export default [
    ...antdArr
]